<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A plastic cube has a length of
        <number-value :value="length" unit="\text{cm}" />
        on each side. If the cube has a mass of
        <number-value :value="mass" unit="\text{g}" />:
      </p>

      <p class="mb-4">a) What is the density of the cube?</p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-4"
        prepend-text="Density"
        append-text="$\text{g/cm}^3$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Would this plastic cube float in water?</p>
      <v-radio-group v-model="inputs.input2" row class="ml-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question188',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
    };
  },
  computed: {
    length() {
      return this.taskState.getValueBySymbol('length').content;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
